import React from "react"
import { Global } from "@emotion/core"
import css from "@styled-system/css"
import { normalize } from "polished"

const GlobalStyles = () => (
  <Global
    styles={css({
      ...normalize(),
      "*": { boxSizing: "border-box" },
      body: {
        m: 0,
        fontFamily: "sans",
        color: "text",
        bg: "text",
      },
      a: {
        textDecoration: "underline",
        color: "inherit",
      },
    })}
  />
)

export default GlobalStyles
