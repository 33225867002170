import colors from "./colors"
import fonts from "./fonts"
import headings from "./headings"
import paragraph from "./paragraph"
import text from "./text"

const space = [0, 2, 4, 8, 12, 16, 24, 32, 40, 48]
space.spacing = space
space.layout = [0, 8, 16, 24, 32, 48, 64, 96, 160]

export default {
  colors,
  fonts,
  headings,
  paragraph,
  space,
  text,
}
