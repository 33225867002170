export default {
  text: "#342e35",
  background: "#f8f8f8",
  modes: {
    dark: {
      text: "#f8f8f8",
      background: "#342e35",
    },
  },
}
