import React from "react"

import { Box } from "components"

const Wrapper = ({ children, id, ...rest }) => (
  <Box id={id} as="section" mt={["layout.1", 0]} pt={[0, "layout.2"]} {...rest}>
    <Box
      px={["layout.4", "layout.6", null, "layout.7"]}
      py={["layout.6", "layout.8"]}
      bg="background"
    >
      <Box width="100%" maxWidth={1200} mx="auto">
        {children}
      </Box>
    </Box>
  </Box>
)

export default Wrapper
