import React from "react"
import css from "@styled-system/css"
import { fluidRange } from "polished"

import { Heading } from "components"

const FluidText = ({ ...rest }) => (
  <Heading
    data-component-id="fluidText"
    size={900}
    css={css({
      ...fluidRange(
        {
          prop: "fontSize",
          fromSize: "48px",
          toSize: "256px",
        },
        "320px",
        "2048px"
      ),
    })}
    {...rest}
  />
)

export default FluidText
