import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title }) {
  const { seo } = useStaticQuery(graphql`
    {
      seo: prismicSeo {
        data {
          title {
            text
          }
          description {
            text
          }
          image {
            url
            alt
          }
        }
      }
    }
  `)

  const metaDescription = seo.data.description && seo.data.description.text

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={seo.data.title && seo.data.title.text}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: seo.data.title && seo.data.title.text,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: seo.data.image && seo.data.image.url,
        },
        {
          property: `og:image:alt`,
          content: seo.data.image && seo.data.image.alt,
        },
        {
          property: `og:image:width`,
          content: `1200`,
        },
        {
          property: `og:image:height`,
          content: `630`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
