import React from "react"
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion"
import css from "@styled-system/css"

import { Box, Heading } from "components"

function AccordionGroup({ id, items, onChange, preExpanded }) {
  return (
    <Accordion allowZeroExpanded={true} preExpanded={preExpanded}>
      {items.map((item, index) => (
        <AccordionItem
          key={"accordionGroup" + id + index}
          uuid={"accordionGroup" + id + index}
          id={item.id}
          css={css({
            pt: ["layout.3", null, "layout.4"],
            pb: ["layout.2", null, "layout.3"],
            borderTop: "1px solid",
            borderColor: "border",
            "&:last-of-type": {
              borderBottom: "1px solid",
              borderColor: "text",
            },
          })}
          onClick={() => {
            if (onChange) {
              onChange(item.object)
            }
          }}
        >
          <AccordionItemHeading>
            <AccordionItemButton
              css={css({
                display: "flex",
                cursor: "pointer",
                "&:focus": { outline: 0 },
                '&[aria-expanded="true"]': {
                  ".control": {
                    "> span:last-of-type": {
                      transform: "translate(-50%, -50%) scaleY(0)",
                    },
                  },
                },
              })}
            >
              <Heading
                children={item.heading}
                as="span"
                size={800}
                flex={1}
                mt="0.1em"
              />
              <Box
                as="span"
                className="control"
                position="relative"
                display="block"
                width={[24, 48]}
                height={[24, 48]}
                ml={3}
                verticalAlign="middle"
              >
                <Box
                  as="span"
                  position="absolute"
                  top="50%"
                  left="50%"
                  width="100%"
                  height={1}
                  bg="text"
                  css={{
                    transform: "translate(-50%, -50%)",
                  }}
                />
                <Box
                  as="span"
                  position="absolute"
                  top="50%"
                  left="50%"
                  width="1px"
                  height="100%"
                  bg="text"
                  css={{
                    transform: "translate(-50%, -50%) scaleY(1)",
                    transformOrigin: "50% 50%",
                    transition: "transform 200ms ease-out",
                  }}
                />
              </Box>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel
            css={css({ pt: "layout.2", pb: [0, "layout.2"] })}
          >
            {item.children}
          </AccordionItemPanel>
        </AccordionItem>
      ))}
    </Accordion>
  )
}

AccordionGroup.defaultProps = {
  color: "text.default",
  id: "accordion",
  items: [],
}

export default AccordionGroup
