import React, { useEffect } from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { ThemeProvider } from "emotion-theming"

import { Box, Footer, GlobalStyles, SpinBadge, Theme } from "components"

import "./fonts.css"

const Layout = ({ children }) => {
  useEffect(() => {
    // Set static viewport height unit on initial load
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
  }, [])

  return (
    <ThemeProvider theme={Theme}>
      <>
        <Helmet>
          <meta name="viewport" content="initial-scale=1, viewport-fit=cover" />
        </Helmet>
        <GlobalStyles />
        <SpinBadge />
        <Box as="main" px={[0, "layout.2"]} pb={[0, "layout.2"]}>
          {children}
        </Box>
        <Footer />
      </>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
