import React, { useState, useEffect } from "react"
import { Link as ScrollLink } from "react-scroll"

import { Box } from "components"

const CX = 96
const CY = CX
const R = CX / 2

function SpinBadge({ ...rest }) {
  const [spinDegree, setSpinDegree] = useState(0)

  const rotate = () => {
    const top = window.pageYOffset || document.documentElement.scrollTop
    setSpinDegree(top / 3)
  }

  useEffect(() => {
    window.addEventListener("scroll", rotate)
    //set rotating function for contact box
    return function cleanup() {
      window.removeEventListener("scroll", rotate)
    }
  }, [])

  return (
    <Box
      position="fixed"
      top={["layout.1", "layout.4"]}
      right={["layout.1", "layout.5"]}
      zIndex={1}
      p="spacing.2"
      border="1px solid"
      borderColor="background"
      borderRadius={9999}
      bg="text"
      style={{ transform: "rotate(" + spinDegree + "deg)" }}
      {...rest}
    >
      <ScrollLink
        to="contact"
        smooth={true}
        style={{ textDecoration: "none", cursor: "pointer" }}
      >
        <Box
          as="svg"
          width={[52, 64]}
          height={[52, 64]}
          viewBox="0 0 144 144"
          style={{
            verticalAlign: "top",
          }}
        >
          <title>Contact</title>
          <defs>
            <path
              id="circlePath"
              d={`M ${CX - R}, ${CY}
                a ${R},${R} 0 1,1 ${R * 2},0
                a ${R},${R} 0 1,1 -${R * 2},0`}
              transform="translate(-24,-24)"
            />
          </defs>
          <text
            fontSize={24}
            fontFamily="NeueMontreal"
            fontWeight={400}
            letterSpacing={2.125}
            fill="#f8f8f8"
            style={{
              textTransform: "uppercase",
            }}
          >
            <textPath xlinkHref="#circlePath">Contact · Contact · </textPath>
          </text>
        </Box>
      </ScrollLink>
    </Box>
  )
}

export default SpinBadge
