import fonts from "./fonts"

export default {
  "600": {
    fontFamily: fonts.sans,
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "32px",
    WebkitFontSmoothing: "antialiased",
    MozOsxFontSmoothing: "grayscale",
    "@media screen and (min-width: 40em)": {
      fontSize: "40px",
      lineHeight: "48px",
    },
  },
  "500": {
    fontFamily: fonts.sans,
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    WebkitFontSmoothing: "antialiased",
    MozOsxFontSmoothing: "grayscale",
    "@media screen and (min-width: 40em)": {
      fontSize: "24px",
      lineHeight: "32px",
    },
  },
  "400": {
    fontFamily: fonts.sans,
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    "@media screen and (min-width: 40em)": {
      fontSize: "16px",
      lineHeight: "24px",
    },
  },
  "300": {
    fontFamily: fonts.sans,
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16px",
    "@media screen and (min-width: 40em)": {
      fontSize: "14px",
      lineHeight: "20px",
    },
  },
}
