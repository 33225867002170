import fonts from "./fonts"

export default {
  "900": {
    fontFamily: fonts.serif,
    fontSize: "48px",
    fontWeight: 100,
    lineHeight: "48px",
    letterSpacing: "-0.01em",
    textTransform: "uppercase",
    "@media screen and (min-width: 40em)": {
      fontSize: "96px",
      lineHeight: "96px",
    },
  },
  "800": {
    fontFamily: fonts.serif,
    fontSize: "24px",
    fontWeight: 100,
    lineHeight: "24px",
    letterSpacing: "-0.01em",
    textTransform: "uppercase",
    "@media screen and (min-width: 40em)": {
      fontSize: "48px",
      lineHeight: "48px",
    },
  },
  "700": {
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "24px",
    textTransform: "uppercase",
    letterSpacing: "0.1em",
  },
  "600": {
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "24px",
    letterSpacing: "-0.008em",
  },
  // Default
  "500": {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "20px",
    letterSpacing: "-0.006em",
  },
  "400": {
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "20px",
    letterSpacing: "-0.003em",
  },
  "300": {
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "16px",
    letterSpacing: 0,
  },
  "200": {
    fontSize: "11px",
    fontWeight: 600,
    lineHeight: "16px",
    letterSpacing: 0,
  },
  "100": {
    fontSize: "10px",
    fontWeight: 600,
    lineHeight: "12px",
    textTransform: "uppercase",
  },
}
