import React from "react"

import { Box, ColorMode, Paragraph } from "components"

const Footer = () => (
  <ColorMode mode="dark">
    <Box
      as="footer"
      px={["layout.4", "layout.6", null, "layout.7"]}
      pb="layout.4"
      bg="background"
    >
      <Box as="section" width="100%" maxWidth={1200} mx="auto">
        <Paragraph
          size={300}
          pb="env(safe-area-inset-bottom)"
          textAlign={["center", "left"]}
        >
          © {new Date().getFullYear()} Guerbois Creative Inc.{" "}
          <Box
            as="a"
            href="https://builtbyfield.com"
            target="_blank"
            rel="noopener noreferrer"
            display={["block", "inline"]}
            mt={["layout.1", 0]}
          >
            Design and code by Field Creative Inc.
          </Box>
        </Paragraph>
      </Box>
    </Box>
  </ColorMode>
)

export default Footer
